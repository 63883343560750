import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'gax-side-panel-dialog-content',
  templateUrl: './side-panel-dialog-content.component.html',
  styleUrls: ['./side-panel-dialog-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SidePanelDialogContentComponent {}
