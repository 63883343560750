@if (summary()) {
  <h4
    [ngClass]="{
      'double-underline': countryCodes().length > 1,
    }"
    (click)="openReadonlyCountriesDialog()"
    (keydown)="handleKeydown($event)"
    tabindex="0"
  >
    {{ summary() }}
  </h4>
} @else if (countryCodes().length === 0) {
  <h4 title="0 countries">&mdash;</h4>
} @else {
  @for (cc of countryCodes(); track cc; let last = $last) {
    <gax-country
      class="break-word"
      [countryCode]="cc"
      [format]="format()"
    /><ng-container *ngIf="!last">, </ng-container>
  }
}
