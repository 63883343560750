@use "../../../assets/sass/utils/variables";

gax-page-section-table-header {
  &.reverse-headers {
    .header-wrapper {
      .header-options {
        flex-direction: row-reverse !important;
      }

      .section-header-table-search-bar {
        border-right: 1px solid #e6e6e6 !important;
      }
    }
  }

  .header-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 1rem;
    border-bottom: 1px solid variables.$grey-10;

    // header with nav
    &:has(nav) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 0;
      border-bottom: 1px solid #e6e6e6;

      .mat-mdc-tab-header {
        flex: 1;
        padding: 0 3.5rem 0 0;
        border-bottom: none !important;
      }

      mat-tab-group.mat-mdc-tab-group .mat-mdc-tab-link-container,
      nav.mat-mdc-tab-nav-bar .mat-mdc-tab-link-container {
        border: none;
      }

      .mat-mdc-tab-header {
        --mdc-secondary-navigation-tab-container-height: 60px;
      }
    }

    // case to cover when a header has a nav and a specific button (example: download button)
    &.nav-header-and-button:has(nav) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0;
      border-bottom: 1px solid #e6e6e6;

      .mat-mdc-tab-header {
        flex: 1;
        padding: 0 3.5rem 0 0;
        border-bottom: none !important;
      }

      mat-tab-group.mat-mdc-tab-group .mat-mdc-tab-link-container,
      nav.mat-mdc-tab-nav-bar .mat-mdc-tab-link-container {
        border-right: 1px solid variables.$grey-10;
      }

      .mat-mdc-icon-button.mat-mdc-button-base {
        color: #8b8b8b;

        &:hover {
          color: #636363 !important;
        }
      }
    }

    // case where the header has a title and a button
    &.title-and-button:has(h2) {
      flex-flow: row !important;
      display: flex !important;
      align-items: center !important;
      gap: 1rem !important;

      h2 {
        flex: auto !important;
        padding: 1rem;
      }

      button {
        padding: 1rem;
      }
    }

    &.removeHeaderBorder {
      border-bottom: none;
    }

    .header-options {
      display: flex;
      align-items: center;
    }

    button.section-header-table-batch-action {
      cursor: pointer;

      &:hover {
        mat-icon.mat-icon {
          color: variables.$green-dark;
        }

        color: variables.$green-dark;
      }
    }

    .mat-mdc-icon-button:not(.pending) {
      gax-button-spinner {
        display: none;
      }
    }

    .mat-mdc-icon-button.pending mat-icon.mat-icon {
      visibility: hidden;

      app-ga-button-spinner {
        visibility: visible;
      }
    }

    .section-header-table-filter {
      display: flex;
      align-items: center;
      padding: 1rem;
      border-left: 1px solid variables.$grey-10;
      cursor: pointer;

      &.active mat-icon {
        color: variables.$green;
      }
    }

    .section-header-table-search-bar {
      display: flex;
      align-items: center;
      padding: 1rem 0 1rem 1rem;
      border-left: 1px solid variables.$grey-10;
      cursor: text;

      // Testing stuff out
      input {
        all: unset;
        min-width: auto !important;
        // width: 0rem;// removing animation
        margin-left: 1rem;
        transition: width 1s ease-out;
        font-size: 1rem;
        width: 17.5rem !important; // removing animation

        // &.focused { // removing animation
        //   width: 17.5rem !important;
        //   transition: width 0.5s ease-out;
        // }
      }

      & > .mat-icon {
        color: hsla(0, 0%, 55%, 1);
      }

      // & > input.mat-input-element {
      //   min-width: 17.5rem;
      // }
    }
  }
}

.section-header-table-filter-panel {
  .mat-expansion-panel-body {
    border-bottom: 1px solid variables.$grey-10;
  }
}
