@use "../../assets/sass/utils/variables";

gax-input-form-field {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 0.5rem;
  outline: none;
  margin-bottom: 1rem;
  max-width: 32rem;

  &:focus-visible .control {
    border-bottom: 2px solid variables.$black;
    padding-bottom: calc(0.5rem - 1px); // Extra focus border size
  }

  &.ng-invalid:not(.ng-untouched) {
    .control {
      border-bottom: 1px solid variables.$red;
      padding-bottom: calc(0.5rem - 1px); // Extra focus border size
    }
  }

  &.ng-invalid.active {
    .control {
      border-bottom: 1px solid variables.$black;
      padding-bottom: calc(0.5rem - 1px); // Extra focus border size
    }

    .control::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: variables.$black;
    }
  }

  &.ng-invalid:focus-visible {
    .control {
      border-bottom: 2px solid variables.$black;
      padding-bottom: calc(0.5rem - 1px); // Extra focus border size
    }

    .control::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: variables.$black;
    }
  }

  &:focus-visible {
    .ga-input-form-field .ng-invalid {
      .control ::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 0;
        background-color: variables.$green;
      }
    }
  }

  &.active .control {
    border-bottom: 1px solid variables.$black;
    padding-bottom: calc(0.5rem - 1px); // Extra focus border size
  }

  .hint,
  .error {
    font-size: 0.75rem;
    font-family: "Open Sans";
    caret-color: transparent;
    line-height: 150%;
    letter-spacing: 0.0125rem;
    min-height: 1.25rem;
  }

  .error {
    color: variables.$red;
    line-height: 150%;
    letter-spacing: 0.0125rem;
    min-height: 1.25rem;
  }

  .label {
    margin-bottom: 0.5rem;
    font-family: "OpenSans-Light";
    line-height: 150%;
    caret-color: transparent;
    font-size: 1rem;
  }

  .control {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.4375rem;
    border-bottom: 0.0625rem solid #949494;
    position: relative;
    margin-bottom: 0.5rem;
    box-sizing: border-box;

    textarea {
      resize: none;
      overflow-y: hidden;
      word-break: break-all;
      white-space: wrap !important;
      padding: 0;
      max-width: 30rem;
      font-family: "OpenSans-ExtraBold";
      width: 100%;
      border: none;
      letter-spacing: normal;
      background: transparent;

      &:focus-visible {
        outline: none;
      }
    }

    &:hover::after:not(.ng-invalid) {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      bottom: -1px;
      position: absolute;
      background-color: variables.$black;
    }

    .suffix {
      display: flex;
      gap: 0.5rem;
    }
  }

  &.active:not(.ng-invalid) {
    .control::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -1px;
      background-color: variables.$black;
    }
  }

  &.ng-invalid.ng-touched {
    .control ::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: variables.$red;
    }
  }
}

.ga-input-form-field--regular {
  textarea {
    font-size: variables.$font-size-header2;
    min-height: 2.25rem;
  }
}

.ga-input-form-field--small {
  textarea {
    font-size: variables.$font-size-header4;
    min-height: 1.5rem;
  }
}

.ga-input-form-field--disabled,
.ga-input-form-field--static {
  caret-color: transparent;
  user-select: none;
  pointer-events: none;
}

.ga-input-form-field--disabled {
  color: variables.$grey-25;

  &:focus-visible .control {
    border-bottom: 1px solid variables.$grey-25;
  }

  textarea {
    color: variables.$grey-25;
  }
}

.ga-input-form-field--static {
  .hint {
    display: none;
  }

  .control {
    border-bottom: none;
  }

  &:focus-visible .control {
    border-bottom: none;
  }
}
