import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import {
  continents as allContinents,
  Continent,
  Country,
  Country2DigitIsoCode,
  CountryNameService,
  ISO_3166_COUNTRIES,
} from 'gain-lib/geography';
import { orderBy } from 'lodash-es';

export interface GaCountrySelectDialogComponentData {
  dialogTitle?: string;
  selectedCountries: string[];
  countryWhitelist?: string[];
  readonlyCountries: string[];
  readOnlyReasonTooltip?: string;
  readOnlyDialog?: boolean;
}

export type GaCountrySelectDialogComponentResult =
  | 'Close'
  | {
      selectedCountries: Country2DigitIsoCode[];
    };

@Component({
  selector: 'gax-country-select-dialog',
  templateUrl: './country-select-dialog.component.html',
  styleUrls: ['./country-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class GaCountrySelectDialogComponent {
  @ViewChild(MatAccordion) accordion?: MatAccordion;
  public readonly dialogTitle?: string;
  public readonly readOnlyDialog?: boolean;

  public readonly countries: readonly Country[];
  public readonly continents: readonly Continent[];
  public readonly countriesByContinent: {
    [continentCode: string]: Country[];
  };
  public readonly countriesByIsoCode: {
    [countryIsoCode: string]: Country;
  };
  public readonly selectedCountries: { [countryIsoCode: string]: Country } = {};
  public readonly searchCtrl = new FormControl<string>('');

  public get selectedCountriesSortedByName(): Country[] {
    return orderBy(Object.values(this.selectedCountries), 'en');
  }

  constructor(
    public dialogRef: MatDialogRef<
      GaCountrySelectDialogComponent,
      GaCountrySelectDialogComponentResult
    >,
    countryName: CountryNameService,
    @Inject(MAT_DIALOG_DATA) public data: GaCountrySelectDialogComponentData,
  ) {
    this.readOnlyDialog = data.readOnlyDialog;
    this.dialogTitle = data.dialogTitle;
    this.countries = ISO_3166_COUNTRIES.filter(
      (c) =>
        data.countryWhitelist == null || data.countryWhitelist.includes(c.iso2),
    ).map((c) => ({
      ...c,
      countryName: countryName.findCountryName(c.iso2) ?? c.en,
    }));

    this.countriesByContinent = this.countries.reduce<{
      [key: string]: Country[];
    }>((cbc, country) => {
      country.continents.forEach((cc) => {
        const ctys = cbc[cc] ?? [];
        ctys.push(country);
        cbc[cc] = ctys;
      });
      return cbc;
    }, {});
    const continents = allContinents.filter(
      (cc) =>
        this.countries.find((c) => c.continents.includes(cc.code)) != null,
    );
    // Sort continents by most countries
    continents.sort(
      (ca, cb) =>
        this.countriesByContinent[cb.code].length -
        this.countriesByContinent[ca.code].length,
    );
    this.continents = continents;
    this.countriesByIsoCode = this.countries.reduce<{ [key: string]: Country }>(
      (ctryByCode, country) => {
        ctryByCode[country.iso2] = country;
        return ctryByCode;
      },
      {},
    );
    Object.freeze(this.countriesByContinent);
    Object.freeze(this.countriesByIsoCode);
    this.selectCountries(...(data.selectedCountries as Country2DigitIsoCode[]));
  }

  getReadOnlyReasonTooltip(country: Country) {
    if (this.isCountryReadOnly(country)) {
      return this.data.readOnlyReasonTooltip ?? '';
    }
    return '';
  }

  readOnlyDialogCheck() {
    return this.readOnlyDialog;
  }

  allCountriesSelected() {
    return (
      Object.keys(this.selectedCountries).length ===
      Object.keys(this.countriesByIsoCode).length
    );
  }

  isSelectAllIndeterminiate() {
    return (
      !this.allCountriesSelected() &&
      Object.keys(this.selectedCountries).length > 0
    );
  }

  isCountryReadOnly(country: Country) {
    return this.data.readonlyCountries.includes(country.iso2);
  }

  showCountry(country: Country) {
    if (this.searchCtrl.value == null) {
      return true;
    }
    const value = this.searchCtrl.value.trim().toUpperCase();
    if (value === '') {
      return true;
    }

    if (
      country.iso2 === value ||
      country.iso3 === value ||
      country.en.toUpperCase().includes(value)
    ) {
      return true;
    }
    return false;
  }

  isCountryChecked(country: Country) {
    return this.selectedCountries[country.iso2] != null;
  }

  isAllIndeterminate() {
    return (
      Object.keys(this.selectedCountries).length > 0 &&
      Object.keys(this.selectedCountries).length <
        Object.keys(this.countriesByIsoCode).length
    );
  }

  disableAllCheckbox() {
    if (
      Object.keys(this.countriesByIsoCode).every((iso2) =>
        this.isCountryReadOnly(this.countriesByIsoCode[iso2]),
      )
    ) {
      return true;
    }

    return false;
  }

  disableContinent(continent: Continent) {
    if (
      this.countriesByContinent[continent.code].every((c) =>
        this.isCountryReadOnly(c),
      )
    ) {
      return true;
    }

    return false;
  }

  isContinentChecked(continent: Continent) {
    // if all countries in continent are selected
    if (
      this.countriesByContinent[continent.code].every((c) =>
        this.isCountryChecked(c),
      )
    ) {
      return true;
    }

    return false;
  }

  availableCountryLength(continent: Continent) {
    const _availableCountryLength = this.countriesByContinent[
      continent.code
    ].filter((c) => this.showCountry(c)).length;
    return _availableCountryLength;
  }

  sortContinents(): Continent[] {
    const continents = allContinents.filter(
      (cc) =>
        this.countries.find(
          (c: Country) => c.continents.includes(cc.code) && this.showCountry(c),
        ) != null,
    );

    // Sort continents by most countries
    return continents.sort(
      (ca, cb) =>
        this.countriesByContinent[cb.code].length -
        this.countriesByContinent[ca.code].length,
    );
  }

  isContinentIndeterminate(continent: Continent) {
    // If less than all countries are selected and countries selected are more than 0
    if (
      this.countriesByContinent[continent.code].filter((c) =>
        this.isCountryChecked(c),
      ).length > 0 &&
      this.countriesByContinent[continent.code].filter((c) =>
        this.isCountryChecked(c),
      ).length < this.countriesByContinent[continent.code].length
    ) {
      return true;
    }

    return false;
  }

  toggleContinent(continent: Continent, change: MatCheckboxChange) {
    // if change.checked and all countries in continent are readonly, do nothing

    if (
      this.countriesByContinent[continent.code].every((c) =>
        this.isCountryReadOnly(c),
      )
    ) {
      ('All countries in continent are readonly');
      return;
    }

    // if change.checked and some countries in continent are not readonly, select them
    if (change.checked) {
      const availableCountries = this.countriesByContinent[
        continent.code
      ].filter((c) => !this.data.readonlyCountries.includes(c.iso2));

      this.selectCountries(...availableCountries.map((c) => c.iso2));
      return;
    }

    // if !change.checked, unselect all countries in continent that are not readonly
    const availableCountries = this.countriesByContinent[continent.code].filter(
      (c) => !this.data.readonlyCountries.includes(c.iso2),
    );

    if (availableCountries.length === 0) {
      return;
    }

    this.unselectCountries(...availableCountries.map((c) => c.iso2));
  }

  toggleCountry(country: Country, change: MatCheckboxChange) {
    if (this.data.readonlyCountries.includes(country.iso2)) return;
    if (change.checked) {
      this.selectCountries(country.iso2);
    } else {
      this.unselectCountries(country.iso2);
    }
  }

  selectCountries(...codes: string[]) {
    codes.forEach((iso2) => {
      this.selectedCountries[iso2] = this.countriesByIsoCode[iso2];
    });
  }

  unselectCountries(...codes: string[]) {
    const filteredcodes = codes.filter(
      (countryIso: string) => !this.data.readonlyCountries.includes(countryIso),
    );

    filteredcodes.forEach((iso2) => {
      delete this.selectedCountries[iso2];
    });
  }

  toggleAll(change: MatCheckboxChange) {
    const filteredcodes = [...Object.keys(this.countriesByIsoCode)].filter(
      (countryIso: string) => !this.data.readonlyCountries.includes(countryIso),
    );

    if (change.checked) {
      this.selectCountries(...filteredcodes);
    } else {
      this.unselectCountries(...filteredcodes);
      this.accordion?.closeAll();
    }
  }

  getCountryTooltip(country: Country) {
    return `${country.en} (${country.iso2}, ${country.iso3})`;
  }

  select() {
    this.dialogRef.close({
      selectedCountries: Object.keys(
        this.selectedCountries,
      ) as Country2DigitIsoCode[],
    });
  }

  close() {
    this.dialogRef.close('Close');
  }
}
