@use "@angular/material";
@use "../../assets/sass/utils/variables";

gax-side-navigation {
  .tree-invisible {
    display: none;
  }

  .main-Container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width: 248px;
    height: 100%;
  }

  .main-list-section {
    background: #ffffff;
    padding: 0px;
    margin: 0px;
    font-weight: 300;
    height: 100%;
    width: 248px;
    border-radius: 0px;
  }

  li.mat-tree-node {
    cursor: pointer;
  }

  .main-list-section li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
    width: 248px;
  }

  .main-list-section ul {
    background: #ffffff;
    font-weight: 300;
    width: 248px;
    border-radius: 0px;
    padding-inline-start: 0px;
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  mat-tree-node.mat-tree-node {
    flex: 0;
    min-height: auto;
  }

  .mat-tree-node-custom {
    border-bottom: 1px solid #e6e6e6;
    height: 58px;
    min-height: 58px;
    width: 248px;
  }

  .label-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 248px;
    margin-left: 16px;
  }

  .label {
    flex-direction: row;
    align-items: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.012em;
    color: #000000;
    width: 100%;
  }

  .icon-left {
    width: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-right {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    margin: 0px 8px;
  }

  gax-icon {
    transition: transform 0.3s ease-out;
    transform: rotate(0deg);

    &.expanded {
      transform: rotate(180deg);
      transition: transform 0.3s ease-out;
    }
  }

  .completed {
    color: #71c72a;
  }

  .incomplete {
    color: #e12621;
  }

  .info {
    color: #bfbfbf;
  }

  .expanded-node {
    background-color: variables.$grey-3;
  }

  .active {
    height: 58px;
    width: 4px;
    border-right: 4px solid variables.$green;
    background-color: variables.$white;

    .label {
      font-weight: 700;
    }
  }
  ul li.active {
    background-color: variables.$grey-10;
  }

  .highlight {
    font-weight: variables.$font-weight-bold;
    background-color: variables.$grey-3;
  }

  mat-tree-node.mat-tree-node[aria-level="2"],
  mat-tree-node.mat-tree-node[aria-level="3"],
  mat-tree-node.mat-tree-node[aria-level="4"],
  mat-tree-node.mat-tree-node[aria-level="5"],
  mat-tree-node.mat-tree-node[aria-level="6"] {
    background-color: #f8f8f8;
  }

  [aria-level="2"] li .label-space {
    margin-left: 40px;
  }

  [aria-level="3"] li .label-section {
    margin-left: 40px;
  }

  [aria-level="4"] li .label-section {
    margin-left: 60px;
  }

  [aria-level="5"] li .label-section {
    margin-left: 80px;
  }

  [aria-level="6"] li .label-section {
    margin-left: 100px;
  }

  .noRoute {
    cursor: not-allowed !important;
  }
}

a:focus mat-icon,
mat-icon {
  animation: none;
}

mat-sidenav.mat-sidenav
  gax-side-navigation
  mat-tree.mat-tree
  mat-tree-node.mat-tree-node
  li.active
  > .label-section
  > .label
  > a {
  font-weight: 700;
  padding: 1rem 0.5rem 1rem 0;
}
