gax-page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: flex-end;

  .header-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  gax-page-header-description {
    max-width: 70rem;
    width: 75%;
  }

  &:has(gax-page-header-actions) {
    gax-page-header-description {
      width: 95%;
    }
  }
}
