<div class="container">
  <div class="title">
    <h2>{{ dialogTitle }}</h2>
  </div>
  <mat-dialog-content>
    <div class="country-lists">
      <div class="left-side">
        <div class="search-bar">
          <mat-icon matPrefix>search</mat-icon>
          <input
            matInput
            placeholder="Enter country name or ISO code"
            [formControl]="searchCtrl"
          />
          <button matSuffix mat-stroked-button (click)="searchCtrl.reset()">
            clear
          </button>
        </div>
        <mat-checkbox
          *ngIf="!readOnlyDialogCheck()"
          class="select-all-countries"
          (change)="toggleAll($event)"
          [checked]="allCountriesSelected()"
          [indeterminate]="isAllIndeterminate()"
          [disabled]="disableAllCheckbox()"
        >
          Select all countries
        </mat-checkbox>
        <div class="country-selection">
          <mat-accordion multi>
            <mat-expansion-panel
              displayMode="flat"
              [expanded]="!!searchCtrl.value"
              *ngFor="let continent of sortContinents()"
            >
              <mat-expansion-panel-header>
                <mat-panel-title (click)="$event.stopPropagation()">
                  <div class="continent">
                    <mat-checkbox
                      (change)="toggleContinent(continent, $event)"
                      [checked]="isContinentChecked(continent)"
                      [indeterminate]="isContinentIndeterminate(continent)"
                      [disabled]="
                        disableContinent(continent) || readOnlyDialogCheck()
                      "
                      ><strong>
                        {{ continent.name }}
                        ({{ availableCountryLength(continent) }})
                      </strong>
                    </mat-checkbox>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <section>
                <div class="countries">
                  <ng-container
                    *ngFor="let country of countriesByContinent[continent.code]"
                  >
                    <span [matTooltip]="getReadOnlyReasonTooltip(country)">
                      <mat-checkbox
                        *ngIf="showCountry(country)"
                        (change)="toggleCountry(country, $event)"
                        [checked]="isCountryChecked(country)"
                        [class.readOnly]="isCountryReadOnly(country)"
                        [title]="getCountryTooltip(country)"
                        [disabled]="
                          isCountryReadOnly(country) || readOnlyDialogCheck()
                        "
                        ><gax-country [countryCode]="country.iso2" />
                      </mat-checkbox>
                    </span>
                  </ng-container>
                </div>
              </section>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <section class="your-selections">
        <h4>Your selection ({{ selectedCountriesSortedByName.length }})</h4>
        <ng-container *ngFor="let country of selectedCountriesSortedByName">
          <span [matTooltip]="getReadOnlyReasonTooltip(country)">
            <mat-checkbox
              *ngIf="showCountry(country)"
              (change)="toggleCountry(country, $event)"
              [disabled]="isCountryReadOnly(country)"
              [checked]="isCountryChecked(country)"
              [class.readOnly]="isCountryReadOnly(country)"
              [title]="getCountryTooltip(country)"
            >
              <gax-country [countryCode]="country.iso2"></gax-country>
            </mat-checkbox>
          </span>
        </ng-container>
      </section>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button color="secondary" mat-button (click)="close()">Close</button>
    <button
      color="primary"
      *ngIf="!readOnlyDialogCheck()"
      mat-button
      (click)="select()"
    >
      Select ({{ selectedCountriesSortedByName.length }}) countries
    </button>
  </mat-dialog-actions>
</div>
