mat-dialog-container.mat-mdc-dialog-container {
  display: flex;
  flex-direction: column;
  padding: 0;

  app-ga-region-select-dialog {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .readOnly > .mdc-form-field > label {
      color: black;
    }

    .title {
      padding: 1rem 1.5rem;
      margin: 0;
      border-bottom: 1px solid lightgray;
      flex: 0;

      h2 {
        font-weight: 800;
        letter-spacing: 0.015rem;
        font-family: "Open Sans";
        margin: 0;
        font-size: 1.5rem;
        padding: 0;
      }
    }

    mat-dialog-content.mat-mdc-dialog-content {
      display: flex;
      flex-direction: column;
      min-height: 30rem;
    }

    .region-lists {
      display: flex;
      overflow: hidden;
      flex-grow: 1;
    }

    .search-bar {
      display: flex;
      padding: 0.6rem;
      border-bottom: 1px solid lightgrey;
      align-items: center;

      mat-icon.mat-icon {
        margin-right: 1rem;
        color: black;
      }

      input.mat-mdc-input-element {
        border: none;
        font-size: 1rem;
        flex-grow: 1;
        line-height: 22px;

        &::placeholder {
          font-weight: 100;
          color: #636363;
        }
      }
    }

    mat-dialog-actions.mat-mdc-dialog-actions {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid lightgray;
      margin: 0;
      padding: 1rem;
    }

    mat-dialog-content.mat-mdc-dialog-content {
      display: flex;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      overflow: hidden;
    }

    .your-selections {
      min-width: 400px;
      padding: 1rem 0 0 1rem;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;

      h4 {
        font-weight: 600;
        color: black;
      }
    }

    .left-side {
      display: flex;
      flex-direction: column;
      border-right: 1px solid lightgrey;
      overflow: hidden;
      width: 100vw;
    }

    .region-selection {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      flex-grow: 1;

      .regions {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        align-items: center;
      }
    }

    .country {
      margin: 1rem 0;
    }

    .select-all-regions {
      border-bottom: 1px solid lightgrey;
      padding: 0.9rem 1rem !important;
    }
  }

  .mat-mdc-expansion-panel-header {
    padding: 0 1rem;
  }

  mat-expansion-panel.mat-expansion-panel {
    box-shadow: none !important;
  }

  .mat-mdc-expansion-panel-body {
    overflow-y: hidden;
  }

  // Removes spacing between expansion panels
  mat-expansion-panel.mat-expansion-panel.mat-expanded.mat-expansion-panel-spacing {
    margin: 0;
  }
}
