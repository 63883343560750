gax-page-section {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.188rem;
  overflow: hidden;
  margin-bottom: 1rem;

  font-size: 1rem;
  font-weight: 300;

  gax-page-section-content:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }
}
