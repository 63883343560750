gax-option-group {
  display: flex;
  flex-direction: column;

  .gax-label {
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: grid;
    grid-auto-columns: auto 1fr;
    grid-auto-flow: column;
    align-items: flex-start;
    padding: 1rem 1rem 0.25rem;
    gap: 1rem;
    font-weight: 700;
  }

  .options {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  gax-option {
    padding-left: 2rem !important;
  }
}
