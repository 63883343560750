import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  GaCountrySelectDialogComponent,
  GaCountrySelectDialogComponentData,
  GaCountrySelectDialogComponentResult,
} from 'gain-lib/country-select/src/country-select/country-select-dialog/country-select-dialog.component';

export type SelecCountriesArgs = {
  countryWhitelist?: string[];
  readonlyCountries?: string[];
  selectedCountries?: string[];
  dialogTitle?: string;
  readOnlyDialog?: boolean;
};

export type SelectCountriesResult =
  | { cancelled: true }
  | { cancelled: false; countries: string[] };

@Injectable({ providedIn: 'root' })
export class CountrySelectService {
  constructor(private _matDialog: MatDialog) {}

  // Invokes the dialog
  public async selectCountries(
    args?: SelecCountriesArgs,
  ): Promise<SelectCountriesResult> {
    const dialogData: GaCountrySelectDialogComponentData = {
      countryWhitelist: args?.countryWhitelist,
      readonlyCountries: args?.readonlyCountries ?? [],
      selectedCountries: args?.selectedCountries ?? [],
      dialogTitle: args?.dialogTitle ?? 'Select countries',
      readOnlyDialog: args?.readOnlyDialog ?? false,
    };

    const ref = this._matDialog.open<
      GaCountrySelectDialogComponent,
      GaCountrySelectDialogComponentData,
      GaCountrySelectDialogComponentResult
    >(GaCountrySelectDialogComponent, {
      minWidth: '900px',
      data: dialogData,
      panelClass: 'country-select-dialog',
    });

    const dialogResults = await ref.afterClosed().toPromise();
    if (dialogResults === 'Close' || !dialogResults) {
      return { cancelled: true };
    }

    return {
      cancelled: false,
      countries: dialogResults.selectedCountries,
    };
  }
}
