import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  CountrySelectService,
  SelecCountriesArgs,
} from 'gain-lib/country-select';
import { CountryDisplayFormat } from 'gain-lib/geography/src/country/country.component';

@Component({
  selector: 'gax-country-inline-list',
  templateUrl: './country-inline-list.component.html',
  styleUrl: './country-inline-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CountryInlineListComponent {
  constructor(private countrySelectService: CountrySelectService) {}

  countryCodes = input.required<string[]>();
  format = input<CountryDisplayFormat | undefined | null>();
  summarizeCount = input<number | undefined | null>();

  summary = computed(() => {
    const countryCount = this.countryCodes().length;
    const summarizeCount = this.summarizeCount();
    if (countryCount > 1) {
      return `${countryCount} countries`;
    }
    if (summarizeCount != null && countryCount >= summarizeCount) {
      return countryCount === 1 ? '1 country' : `${countryCount} countries`;
    }
    return null;
  });

  async openReadonlyCountriesDialog() {
    const args: SelecCountriesArgs = {
      selectedCountries: this.countryCodes(),
      readonlyCountries: this.countryCodes(),
      dialogTitle: 'Selected countries',
      readOnlyDialog: true,
    };

    const result = await this.countrySelectService.selectCountries(args);

    if (!result.cancelled) {
      return { cancelled: false, countries: result.countries };
    } else {
      return { cancelled: true };
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.openReadonlyCountriesDialog();
    }
  }
}
