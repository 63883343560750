gax-page-header-description {
  display: inline-block;

  > div {
    font-weight: 300;

    p {
      font-weight: 300;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    > * {
      font-weight: 300;
    }
  }
}
