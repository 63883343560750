const emptyString = '';
const whitespaceChars = [emptyString, ' ', '\t'];

export function isEmpty(value: string) {
  return value === emptyString;
}

export function isWhitespace(value: string) {
  return value.split('').every((c) => whitespaceChars.includes(c));
}

export function isNotNullishOrWhitespace(
  value: string | null | undefined,
): value is string {
  return value != null && !isWhitespace(value);
}

export function isNullishOrWhitespace(value: string | null | undefined) {
  return value == null || isWhitespace(value);
}
