// $error-color: #e12621;
///////////////////////////
// Spacings - DEPRICATED //
///////////////////////////
$spacing-auto: auto !default;
$spacing-0: 0 !default;
$spacing-1: 0.25rem !default;
$spacing-2: 0.5rem !default;
$spacing-3: 0.75rem !default;
$spacing-4: 1rem !default;
$spacing-5: 1.25rem !default;
$spacing-6: 1.5rem !default;
$spacing-7: 1.75rem !default;
$spacing-8: 2rem !default;
$spacing-9: 2.25rem !default;
$spacing-10: 2.5rem !default;
$spacing-11: 2.75rem !default;
$spacing-12: 3rem !default;
$spacing-13: 3.25rem !default;
$spacing-14: 3.5rem !default;
$spacing-15: 3.75rem !default;
$spacing-16: 4rem !default;

$spacing: (
  auto: $spacing-auto,
  0: $spacing-0,
  1: $spacing-1,
  2: $spacing-2,
  3: $spacing-3,
  4: $spacing-4,
  5: $spacing-5,
  6: $spacing-6,
  7: $spacing-7,
  8: $spacing-8,
  9: $spacing-9,
  10: $spacing-10,
  11: $spacing-11,
  12: $spacing-12,
  13: $spacing-13,
  14: $spacing-14,
  15: $spacing-15,
  16: $spacing-16,
);

/////////////////
// Font Family //
/////////////////
$font-open-sans: "Open Sans";

$font-weight-black: 900 !default;
$font-weight-extra-bold: 800 !default;
$font-weight-bold: 700 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-medium: 500 !default;
$font-weight-regular: 400 !default;
$font-weight-light: 300 !default;
$font-weight-extra-light: 200 !default;
$font-weight-thin: 100 !default;

$font-weight: (
  black: $font-weight-black,
  extra-bold: $font-weight-extra-bold,
  bold: $font-weight-bold,
  semi-bold: $font-weight-semi-bold,
  medium: $font-weight-medium,
  regular: $font-weight-regular,
  light: $font-weight-light,
  extra-light: $font-weight-extra-light,
  thin: $font-weight-thin,
);

////////////
// Sizing //
////////////
$font-size-header1: 32px;
$font-size-header2: 24px;
$font-size-header3: 20px;
$font-size-header4: 16px;
$font-size-body1: 16px;
$font-size-body2: 14px;
$font-size-subtitle1: 16px;
$font-size-subtitle2: 14px;
$font-size-subtitle3: 12px;
$font-size-subtitle4: 10px;
$font-size-button-normal: 16px;
$font-size-botton-small: 13px;

$font-size: (
  body1: $font-size-body1,
  body2: $font-size-body2,
  subtitle1: $font-size-subtitle1,
  subtitle2: $font-size-subtitle2,
  button-normal: $font-size-button-normal,
);

/* Letter spacing*/
$letter-spacing-small: -0.96px;
$letter-spacing-medium: 0.192px;
$letter-spacing-large: 0.98px;
$letter-spacing-xlarge: 1.5px;

/* Colors */
$black: #000;
$color-black: #000; //temp
$black-alpha-90: rgba(0, 0, 0, 0.87);
$black-20: #333;
$black-30: #777;
$black-40: #828282;
$black-50: #8b8b8b;
$color-gray-1: #e6e6e6; // Temp
$grey: #fcfcfc;
$grey-3: #f8f8f8;
$grey-4: #f2f2f2;
$grey-5: #e0e0e0;
$grey-10: #e6e6e6;
$grey-25: #bfbfbf;
$grey-30: #999;
$grey-50: #8b8b8b;
$grey-75: #636363;
$grey-85: #262626;

$white: #fff;
$white-alpha-90: rgba(255, 255, 255, 0.87);
$white-20: #f8f8f8;

$green-deloitte: #86bc25;
$green: #8fdc4f;
$green-wash: #f6fcf1;
$green-wash-light: #fbfdf8;
$green-light: #aff475;
$green-dark: #71c72a;
$green-slightly-darker: #4ca900;
$green-darker: #49a300;

$teal: #1797a8;
$teal-wash: #43c4d6;
$teal-light: #43c4d6;
$teal-dark: #0a808f;

$blue: #1baeec;
$blue-wash: #74d6ff;
$blue-light: #74d6ff;
$blue-dark: #0096d6;

$yellow: #fed430;
$yellow-wash: #fffbec;
$yellow-wash-light: #fffdf7;
$yellow-light: #ffe78b;
$yellow-dark: #ecbf10;

$red: #e12621;
$red-wash: #fcf0ef;
$red-wash-light: #fcf8f8;
$red-light: #f4524d;
$red-dark: #c51713;

$orange: #f18f24;
$orange-wash: #ffb96d;
$orange-light: #ffb96d;
$orange-dark: #e27d0f;

$background-color: $white-20;
$nav-bg-color: $white;

$primary-color: $green;
$primary-lighter-color: $green-light;
$primary-darker-color: $green-dark;

$accent-color: $blue;
$accent-lighter-color: $blue-light;
$accent-darker-color: $blue-dark;

$text-accent-color: $white;
$text-accent-lighter-color: $black-alpha-90;
$text-accent-darker-color: $white;

$line-color: $grey;
$text-primary-color: $black-alpha-90;
$text-primary-lighter-color: $black-alpha-90;
$text-primary-darker-color: $black-alpha-90;

$success-color: $green;
$success-color-highlight: $green-wash;

$warn-color: $red;
$warn-color-highlight: $red-wash;

$max-width-tablet: 1023px;
$max-width-mobile: 600px;
